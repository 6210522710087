const $ = require('jquery')
window.$ = $

const Popper = require('popper.js').default
window.Popper = Popper

require('bootstrap')

const StackedMenu = require('stacked-menu').default
window.StackedMenu = StackedMenu

window.showModal = function(selector) {
  $(selector).modal('show')
}

window.copyToClipboard = function(textElementId) {
  var textElement = document.getElementById(textElementId)
  if(textElement.nodeName === "INPUT") {
    textElement.select()
    document.execCommand('copy')
  } else {
    var range = document.createRange();
    range.selectNode(textElement);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  }
}